import { useAlert, useAuth } from 'App'
import { useEffect, useState } from 'react'
import { io } from 'socket.io-client'
import RecordRTC, { StereoAudioRecorder } from 'recordrtc'

const requestRecorder = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false
    })

    return stream
  } catch (error2) {
    throw error2
  }
}

const useRecorder = () => {
  const [audioURL, setAudioURL] = useState('')
  const [isRecording, setIsRecording] = useState(false)
  const [recorder, setRecorder] = useState(null)
  const [stream, setStream] = useState(null)
  const [responseSocketMicrosoft, setResponseSocketMicrosoft] = useState(null)
  const [responseSocketGoogle, setResponseSocketGoogle] = useState(null)
  const [responseSocketVoxist, setResponseSocketVoxist] = useState(null)
  const [socket, setSocket] = useState(null)
  const [language, setLanguage] = useState('fr-FR')
  const [isVoxist, setIsVoxist] = useState(true)
  const [voc, setVoc] = useState(null)
  const [model, setModel] = useState('slow')
  const auth = useAuth()
  const { setAlert } = useAlert()
  const handleData = async (e: Blob) => {
    if (!isVoxist) {
      socket.emit('binaryData', e)
    }
    socket.emit('voxist', e)
    if (!isVoxist) {
      socket.emit('data', await e.arrayBuffer(), language)
    }
  }
  useEffect(() => {
    if (!recorder) {
      if (isRecording) {
        requestRecorder()
          .then((response) => {
            const recorder = RecordRTC(response, {
              type: 'audio',
              recorderType: StereoAudioRecorder,
              mimeType: 'audio/wav',
              timeSlice: 500,
              desiredSampRate: 16000,
              numberOfAudioChannels: 1,
              disableLogs: false,
              ondataavailable: (e) => handleData(e)
            })
            setRecorder(recorder)
            setStream(response)
          })
          .catch((e) => {
            setAlert({ type: 'error', text: "can't get recorder" })
          })
      }
      return
    }
    if (isRecording) {
      if (!isVoxist) {
        socket.emit('startGoogleCloudStream', language)
        socket.emit('startMicrosoftCloudStream', language)
      }

      socket.emit(
        'startVoxistStream',
        language,
        voc ? voc?.replace(' ', '').split(',') : null,
        model
      )
      recorder.startRecording()
    } else {
      if (!isVoxist) {
        socket.emit('endGoogleCloudStream')
      }

      socket.emit('endVoxistStream')
      recorder.stopRecording((blobURL) => setAudioURL(blobURL))
      setRecorder(null)

      stream
        ?.getTracks() // get all tracks from the MediaStream
        ?.forEach((track) => track.stop())
      setStream(null)
      setTimeout(() => {
        socket.emit('logs', {
          google: responseSocketGoogle,
          microsoft: responseSocketMicrosoft,
          voxist: responseSocketVoxist
        })
      }, 1000)
      // socket.close();
    }
    socket.on('connect', () => {
      // console.log(socket.id) // x8WIv7-mJelg7on_ALbx
    })

    socket.on('disconnect', () => {
      // console.log(socket.id) // undefined
    })
    socket.on('connect_error', async () => {
      try {
        const token = await auth.getToken()
        socket.auth = { token }
        socket.connect()
      } catch (error) {
        setAlert({ type: 'error', text: "can't get auth" })
      }
    })
    socket.on('speechDataGoogle', (arg) => {
      // console.log(arg)
      setResponseSocketGoogle(arg)
    })
    socket.on('response', (arg) => {
      // console.log(arg)
      setResponseSocketMicrosoft(arg)
    })
    socket.on('voxistResponse', (arg) => {
      setResponseSocketVoxist(arg)
    })

    // recorder.addEventListener('ondataavailable', handleData)
    return () => {
      // recorder.removeEventListener('ondataavailable', handleData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recorder, isRecording])
  const startRecording = async () => {
    try {
      const token = await auth.getToken()

      if (process.env.REACT_APP_DOCKER) {
        setSocket(
          io({
            auth: { token },
            transports: ['websocket']
          })
        )
      } else {
        setSocket(
          io('localhost:80', {
            auth: { token },
            transports: ['websocket']
          })
        )
      }
    } catch (error) {
      setAlert({ type: 'error', text: "can't connect socket" })
    }

    setResponseSocketGoogle(null)
    setResponseSocketMicrosoft(null)
    setResponseSocketVoxist(null)
    URL.revokeObjectURL(audioURL)
    setAudioURL(null)

    setIsRecording(true)
  }
  const stopRecording = () => {
    setIsRecording(false)
  }

  return [
    audioURL,
    isRecording,
    startRecording,
    stopRecording,
    language,
    setLanguage,
    responseSocketGoogle,
    responseSocketMicrosoft,
    setResponseSocketGoogle,
    setResponseSocketMicrosoft,
    responseSocketVoxist,
    setResponseSocketVoxist,
    setAudioURL,
    isVoxist,
    setIsVoxist,
    voc,
    setVoc,
    model,
    setModel
  ]
}

export default useRecorder
